import React from 'react'
import styled from 'styled-components'
import { BodyColor } from 'src/TempleDuCiel/BookStore'
import bodyLight from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0018_BODY-TAGS-character-hero_body-color-light.png'
import bodyMedium from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0019_BODY-TAGS-character-hero_body-color-medium.png'
import bodyDark from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0020_BODY-TAGS-character-hero_body-color-dark.png'

const bodyColorsFiles = {
  light: bodyLight,
  medium: bodyMedium,
  dark: bodyDark,
}

const Image = styled.img`
  position: absolute;
`

export const bodyColors = {
  light: '#F0AC91',
  medium: '#C56C4E',
  dark: '#6C2C18',
}

interface ImageBodyProps {
  bodyColor: BodyColor;
}

export function ImageBody(props: ImageBodyProps) {
  const { bodyColor } = props
  const src = bodyColorsFiles[bodyColor]

  return (
    <Image
      alt="body"
      src={src}
      width="421"
    />
  )
}
