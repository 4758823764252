/* eslint-disable react/no-danger */
/* eslint-disable import/no-default-export */
import React from 'react'
import styled from 'styled-components'
import { Text as TextBase } from 'src/TempleDuCiel/components/Text'
import { useText } from 'src/TempleDuCiel/hooks/useText'
import {
  WBObjectModel,
  useWBObjectContext,
  WBObjectModelDefaultConfig,
} from 'src/WBApp/WBAppCore'
import { stateTransitions, CSSTransitionTimeout } from 'src/WBApp/WBAppWeb'

interface Data {
  text: string;
}

export type Text1Model = WBObjectModel<'Text', Data>

export const text1Config: WBObjectModelDefaultConfig<Data> = {
  transitionTimeout: 0,
}

const TextContainer = styled(TextBase)`
  position: absolute;
  width: 100%;
  font-size: 24px;
  text-align: center;
  padding-top: 50px;
  transition: all ${CSSTransitionTimeout()};
  padding-left: 100px;
  padding-right: 100px;
  box-sizing: border-box;

  ${stateTransitions.notAppeared} {
    opacity: 0;
  }

  ${stateTransitions.appear} {
    opacity: 1;
  }

  ${stateTransitions.disappear} {
    opacity: 0;
  }
`

export function Text1() {
  const object = useWBObjectContext<Text1Model>()
  const text = useText(object.data.text)

  return (
    <TextContainer>
      {text}
    </TextContainer>
  )
}
