import React from 'react'
import styled from 'styled-components'
import { BookStore } from 'src/TempleDuCiel/BookStore'
import {
  WBObjectModel,
  WBObjectModelDefaultConfig,
  useWBObjectContext,
  useWBAppContext,
  useOnExisting,
  useWBAllowPushScene,
} from 'src/WBApp/WBAppCore'
import {
  stateTransitions,
  CSSTransitionTimeout,
} from 'src/WBApp/WBAppWeb'
import { useStateWithGetter } from 'src/hooks/useStateWithGetter'

export interface Data {
  characterKey: 'heroName' | 'heroSisterName';
}

export type CharacterNameModel = WBObjectModel<'CharacterName', Data>

export const characterNameConfig: WBObjectModelDefaultConfig<Data> = {
  transitionTimeout: 1500,
}

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  transition: all ${CSSTransitionTimeout()};

  ${stateTransitions.notAppeared},
  ${stateTransitions.disappear} {
    opacity: 0;
  }

  ${stateTransitions.appear} {
    opacity: 1;
  }
`

const Text = styled.div`
  font-size: 24px;
  text-align: center;
  padding-top: 50px;
  margin-bottom: 40px;
`

const Input = styled.input`
  padding: 10px;
  border-radius: 10px;
  border: solid 1px #ccc;
  font-size: 25px;
  outline: none;
  text-align: center;
  transition: all 1s;
  margin-top: 250px;

  ${stateTransitions.notAppeared} {
    margin-left: 100px;
  }

  ${stateTransitions.appeared} {
    margin-left: 0px;
  }

  ${stateTransitions.disappeared} {
    margin-left: -100px;
  }
`

export function CharacterName() {
  const app = useWBAppContext<BookStore>()
  const [characterName, setCharacterName] = useStateWithGetter('')
  const object = useWBObjectContext<CharacterNameModel>()

  useOnExisting(() => {
    app.setAppStore({
      [object.data.characterKey]: characterName,
    })
  })

  useWBAllowPushScene(characterName.length > 0)

  return (
    <Container>
      <div>
        <Input onChange={(e) => setCharacterName(e.target.value)} value={characterName} />
      </div>
    </Container>
  )
}
