import { useEffect } from 'react'
import { usePrevious } from 'src/hooks/usePrevious'
import { useWBAppContext } from './context'

export function useWBAllowPushScene(value: boolean) {
  const prevValue = usePrevious(value)
  const app = useWBAppContext()

  useEffect(() => {
    if (prevValue === false && value === true) {
      app.setAppState({
        allowPushScene: true,
      })
    }
  }, [app, prevValue, value])
}
