import React from 'react'
import { Reset } from 'styled-reset'
import { WBAppCore } from 'src/WBApp/WBAppCore'
import { WBAppWebProps } from './WBAppWeb.types'

export function WBAppWeb(props: WBAppWebProps) {
  const { childrenBeforeScenes, ...restProps } = props
  return (
    <WBAppCore
      {...restProps}
      childrenBeforeScenes={(
        <>
          <Reset />
          {childrenBeforeScenes}
        </>
      )}
    />
  )
}
