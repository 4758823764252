import React, { useMemo, useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { ThemeProvider } from 'styled-components'
import { usePrevious } from 'src/hooks/usePrevious'
import * as asserts from 'src/utils/asserts'
import { WBObjectModel, WBSceneContextValue } from './WBApp.types'
import { WBObject } from './WBObject'
import { SceneContext, useWBAppContext } from './context'

interface FormatedObjectModel extends WBObjectModel {
  isCurrent: boolean;
  isNext: boolean;
  isPersisted: boolean;
  objectKey: string;
  sceneContext: WBSceneContextValue;
}

export function WBScenes() {
  const app = useWBAppContext()
  const { currentSceneId: appCurrentSceneId } = app.getAppState()
  const prevAppCurrentSceneId = usePrevious(appCurrentSceneId)
  const appCurrentSceneIdHasChanged = prevAppCurrentSceneId !== appCurrentSceneId

  const [currentSceneId, setCurrentSceneId] = useState<string | null>(null)
  const [nextSceneId, setNextSceneId] = useState<string | null>(null)

  const [inTransition, setInTransition] = useState(false)
  const prevInTransition = usePrevious(inTransition)

  const currentSceneModel = currentSceneId ? app.getSceneModel(currentSceneId) : null
  const nextSceneModel = nextSceneId ? app.getSceneModel(nextSceneId) : null

  useEffect(() => {
    if (appCurrentSceneIdHasChanged) {
      // console.log('### transition start')
      setNextSceneId(appCurrentSceneId)
      setInTransition(true)

      // reset allowPushScene on scene load
      // const appCurrentSceneModel = app.getSceneModel(appCurrentSceneId)
      app.setAppState({ allowPushScene: false })
      // if (appCurrentSceneModel.allowPushScene === false) {
      //   app.setAppState({ allowPushScene: false })
      // }
    }
  }, [app, appCurrentSceneIdHasChanged, appCurrentSceneId])

  const currentSceneObjects = currentSceneModel?.objects || []
  const nextSceneObjects = nextSceneModel?.objects || []

  let nextSceneObjectsFiltered = nextSceneObjects.slice()

  if (nextSceneObjectsFiltered) {
    nextSceneObjectsFiltered = nextSceneObjectsFiltered.filter((object) => {
      const objectAlreadyHere = object.id && currentSceneObjects.some(({ id }) => id === object.id)
      return !objectAlreadyHere
    })
  }

  const currentSceneModelContext = useMemo(() => ({
    isExiting: inTransition,
    isEntered: !inTransition,
    model: currentSceneModel,
  }), [inTransition, currentSceneModel])

  const nextSceneModelContext = useMemo(() => ({
    isExiting: false,
    isEntered: false,
    model: nextSceneModel,
  }), [nextSceneModel])

  const formatedCurrentObjects = !currentSceneModel
    ? []
    : currentSceneObjects.map((object, index): FormatedObjectModel => {
      const objectKey = object.id ?? `${currentSceneModel.id}-${index}-${object.type}`
      const isPersisted = !!nextSceneModel?.objects.some(({ id }) => id === objectKey)

      asserts.isDefined(currentSceneModelContext.model)

      return {
        ...object,
        isCurrent: true,
        isNext: false,
        isPersisted,
        objectKey,
        // @ts-ignore
        sceneContext: currentSceneModelContext,
      }
    })

  let formatedNextObjects = !nextSceneModel
    ? []
    : nextSceneModel.objects.map((object, index): FormatedObjectModel => {
      const objectKey = object.id ?? `${nextSceneModel.id}-${index}-${object.type}`
      const isPersisted = currentSceneObjects.some(({ id }) => id === objectKey)

      return {
        ...object,
        isCurrent: false,
        isNext: true,
        isPersisted,
        objectKey,
        // @ts-ignore
        sceneContext: nextSceneModelContext,
      }
    })

  formatedNextObjects = formatedNextObjects.filter((object) => !object.isPersisted)

  const allObjects = [
    ...formatedCurrentObjects,
    ...formatedNextObjects,
  ]

  type UseRefPromiseResolver = {
    [objectKey: string]: (() => void);
  }

  const enteredPromisesResolverRef = useRef<UseRefPromiseResolver>({})
  const exitedPromisesResolverRef = useRef<UseRefPromiseResolver>({})

  if (!prevInTransition && inTransition) {
    // enter
    const enteringObjectKey = allObjects
      .filter((object) => object.isNext)
      .map(({ objectKey }) => objectKey)

    const promisesEntered = enteringObjectKey.map((objectKey) => {
      return new Promise((resolve) => {
        enteredPromisesResolverRef.current[objectKey] = resolve
      })
    })

    // exist
    const exitingObjectKey = allObjects
      .filter((object) => object.isCurrent && !object.isPersisted)
      .map(({ objectKey }) => objectKey)

    const promisesExisted = exitingObjectKey.map((objectKey) => {
      return new Promise((resolve) => {
        exitedPromisesResolverRef.current[objectKey] = resolve
      })
    })

    const promises = [
      ...promisesEntered,
      ...promisesExisted,
    ]

    if (promises.length) {
      Promise.all(promises).then(() => {
        ReactDOM.unstable_batchedUpdates(() => {
          // console.log('### transition end')
          setCurrentSceneId(appCurrentSceneId)
          setNextSceneId(null)
          setInTransition(false)

          const appCurrentSceneModel = app.getSceneModel(appCurrentSceneId)
          if (appCurrentSceneModel.allowPushScene !== false) {
            app.setAppState({ allowPushScene: true })
          }
        })
      })
    }
  }

  return (
    <>
      {allObjects.map((object) => {
        const {
          type,
          objectKey,
          isCurrent,
          isNext,
          isPersisted,
          transitionTimeout,
          transitionInTimeout,
          transitionOutTimeout,
          sceneContext,
        } = object

        if (!app.appObjects[type]) {
          throw new Error(`WBScenes: object type ${type} is not defined`)
        }

        const { config: objectConfig } = app.appObjects[type]

        const defaultTimeout = objectConfig.transitionTimeout ?? 0

        const timeout = isNext
          ? (transitionInTimeout ?? transitionTimeout ?? defaultTimeout)
          : (transitionOutTimeout ?? transitionTimeout ?? defaultTimeout)

        const inValue = inTransition ? (isPersisted || isNext) : true

        return (
          <CSSTransition
            key={objectKey}
            appear={true}
            classNames="scene"
            in={inValue}
            mountOnEnter={isCurrent}
            onEntered={enteredPromisesResolverRef.current[objectKey]}
            onExited={exitedPromisesResolverRef.current[objectKey]}
            timeout={timeout}
            unmountOnExit={true}
          >
            <SceneContext.Provider value={sceneContext}>
              <ThemeProvider theme={(theme: object) => ({ ...theme, transitionTimeout: timeout })}>
                <WBObject model={object} />
              </ThemeProvider>
            </SceneContext.Provider>
          </CSSTransition>
        )
      })}
    </>
  )
}
