import React, { useEffect } from 'react'
import { BodyColor, HairColor, HairType, BookStore } from 'src/TempleDuCiel/BookStore'
import { WBObjectModel, WBObjectModelDefaultConfig, useWBAppContext } from 'src/WBApp/WBAppCore'
import { useStateWithGetter } from 'src/hooks/useStateWithGetter'
import { ImageBody, bodyColors } from './ImageBody'
import { ImageHair, hairColors } from './ImageHair'
import { PaletteColor } from './PaletteColor'
import { Container, Hero, Palette } from './SceneCustomHero.styles'
import { SelectHairType } from './SelectHairType'

interface SceneCustomHeroData {}

export type SceneCustomHeroModel = WBObjectModel<'SceneCustomHero'>

export const sceneCustomHeroConfig: WBObjectModelDefaultConfig<SceneCustomHeroData> = {
  transitionTimeout: 1000,
}

export function SceneCustomHero() {
  const app = useWBAppContext<BookStore>()
  const [bodyColor, setBodyColor] = useStateWithGetter<BodyColor>('light')
  const [hairType, setHairType] = useStateWithGetter<HairType>('longCurly')
  const [hairColor, setHairColor] = useStateWithGetter<HairColor>('blond')

  useEffect(() => {
    app.setAppStore({
      heroBodyColor: bodyColor,
      heroHairColor: hairColor,
      heroHairType: hairType,
    })
  }, [app, bodyColor, hairType, hairColor])

  return (
    <Container>
      <Hero>
        <ImageBody
          bodyColor={bodyColor}
        />
        <ImageHair
          hairColor={hairColor}
          hairType={hairType}
        />
      </Hero>
      <Palette>
        <PaletteColor
          colors={bodyColors}
          onChange={(value) => setBodyColor(value)}
          value={bodyColor}
        />
        <SelectHairType onChange={(value) => setHairType(value)} value={hairType} />
        <PaletteColor
          colors={hairColors}
          onChange={(value) => setHairColor(value)}
          value={hairColor}
        />
      </Palette>
    </Container>
  )
}
