import { useContext, createContext } from 'react'
import { WBAppContextValue, WBSceneContextValue, WBObjectModel } from './WBApp.types'

export const AppContext = createContext<WBAppContextValue>({} as WBAppContextValue)

export function useWBAppContext<StoreData extends object = {}, ExtraData extends object = {}>(): WBAppContextValue<StoreData, ExtraData> {
  // @ts-ignore
  return useContext(AppContext)
}

export const SceneContext = createContext<WBSceneContextValue>({} as WBSceneContextValue)

export function useWBSceneContext() {
  return useContext(SceneContext)
}

export const ObjectContext = createContext<WBObjectModel>({} as WBObjectModel)

export function useWBObjectContext<TObjectModel>(): TObjectModel {
  // @ts-ignore
  return useContext(ObjectContext)
}
