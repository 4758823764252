import { useCallback } from 'react'
import { Setter, Getter } from '../../hooks/useStateWithGetter'
import { WBAppState, WBAppStore, WBStory } from './WBApp.types'

export function useGetSceneModel(story: WBStory) {
  return useCallback((sceneId: string) => {
    return story.scenes[sceneId]
  }, [story.scenes])
}

export function usePushScene(getAppState: Getter<WBAppState>, setAppState: Setter<WBAppState>, setAppStore: Setter<WBAppStore>) {
  return useCallback((sceneId: string) => {
    if (getAppState().allowPushScene) {
      setAppState((state) => ({ ...state, currentSceneId: sceneId }))
      setAppStore((store) => ({ ...store, path: [...store.path, sceneId] }))
    }
  }, [setAppState, getAppState, setAppStore])
}
