
import { css } from 'styled-components'
import { stateTransitions } from './stateTransitions'

interface PropsCSSTheme {
  theme: {
    appSize: {
      height: number;
      width: number;
    };
    transitionTimeout: number;
  };
}

export function CSSTransitionTimeout(
  /**
   * Timeout in milliseconds
   */
  defaultTimeout?: number,
) {
  return (props: PropsCSSTheme) => {
    const milliseconds = props.theme.transitionTimeout ?? defaultTimeout ?? 0
    return `${milliseconds / 1000}s`
  }
}

export function CSSTheme(fn: (props: PropsCSSTheme) => string | number | undefined) {
  return (props: PropsCSSTheme) => fn(props)
}

export function CSSTransitionFade() {
  return css`
    transition: all ${CSSTransitionTimeout()};

    ${stateTransitions.notAppeared} {
      opacity: 0;
    }

    ${stateTransitions.appear} {
      opacity: 1;
    }

    ${stateTransitions.disappear} {
      opacity: 0;
    }
  `
}
