import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import { /* bagItems, BagItemKey */ } from 'src/TempleDuCiel/BookStore'
import { useWBAppTempleDuCiel } from 'src/TempleDuCiel/hooks/useWBAppTempleDuCiel'
import { WBObjectModel, WBObjectModelDefaultConfig, useWBObjectContext } from 'src/WBApp/WBAppCore'
import { BagItem } from './BagItem'

type BagItemKey = string;

interface Data {
  JSONData: {
    objectIds: string[];
  };
}

export type SceneBagModel = WBObjectModel<'SceneBag', Data>

export const sceneBagConfig: WBObjectModelDefaultConfig<Data> = {}

const UI = {
  Container: styled.div`
    position: absolute;
    max-height: 500px;
    overflow: hidden;
    margin-left: 100px;
    margin-top: 140px;
    display: flex;
    flex-direction: column;
  `,
  ListContainer: styled.div`
    overflow: auto;
    /* flex: 1; */
  `,
  RemainingSize: styled.div`
    margin-bottom: 20px;
  `,
}

function useBagSizeRemaining() {
  const app = useWBAppTempleDuCiel()
  const object = useWBObjectContext<SceneBagModel>()

  const bagItems = app.extraData.props
    .filter((item) => object.data.JSONData.objectIds.includes(item.id))
    .reduce((a, b) => ({ ...a, [b.id]: b }), {})

  const bagSize = 22
  const bagItemsKeyUsed = app.getAppStore().heroProps
  // @ts-ignore
  const bagItemsUsed = bagItemsKeyUsed.map((key) => bagItems[key])
  const bagSizeUsed = bagItemsUsed.map((item) => item.size).reduce((a, b) => a + b, 0)
  const bagSizeRemaining = bagSize - bagSizeUsed

  return bagSizeRemaining
}

export function SceneBag() {
  const app = useWBAppTempleDuCiel()
  const bagSizeRemaining = useBagSizeRemaining()
  const object = useWBObjectContext<SceneBagModel>()
  const defaultHeroProps = useRef(app.getAppStore().heroProps)

  const heroProps: { [key: string]: any; } = app.extraData.props
    .filter((item) => object.data.JSONData.objectIds.includes(item.id))
    .reduce((a, b) => ({ ...a, [b.id]: b }), {})

  const itemIsSelected = useCallback((itemId) => {
    return app.getAppStore().heroProps.some((_) => _ === itemId)
  }, [app])

  const itemIsOptional = useCallback((itemId) => {
    return !defaultHeroProps.current.some((_) => _ === itemId)
  }, [defaultHeroProps])

  const onClickBagItem = useCallback((itemId: string) => {
    if (!itemIsSelected(itemId)) {
      const item = heroProps[itemId]
      if (item.size > bagSizeRemaining) {
        // bag full
        return
      }

      app.setAppStore((store) => ({
        heroProps: [...store.heroProps, itemId],
      }))
    } else {
      const item = heroProps[itemId]
      if (!itemIsOptional(item.id)) {
        // item can't be removed
        return
      }

      app.setAppStore((store) => ({
        heroProps: store.heroProps.filter((_) => _ !== itemId),
      }))
    }
  }, [app, itemIsSelected, bagSizeRemaining, heroProps, itemIsOptional])

  const bagItemsList = Object.entries(heroProps).map(([itemId, item]) => (
    <BagItem
      key={itemId}
      isOptional={itemIsOptional(itemId)}
      isSelected={itemIsSelected(itemId)}
      itemId={itemId as BagItemKey}
      name={item.name}
      onClick={onClickBagItem}
      size={item.size}
    />
  ))

  return (
    <UI.Container>
      <UI.RemainingSize>
        Places retantes: {bagSizeRemaining}
      </UI.RemainingSize>
      <UI.ListContainer>
        {bagItemsList}
      </UI.ListContainer>
    </UI.Container>
  )
}
