import React, { useRef } from 'react'
import styled from 'styled-components'
import { BookStore } from 'src/TempleDuCiel/BookStore'
import { ImageFullSize } from 'src/TempleDuCiel/components/ImageFullSize'
import { useWBAppContext } from 'src/WBApp/WBAppCore'
import * as asserts from 'src/utils/asserts'

interface CharacterMetaImage {
  bodyColor?: string;
  hairColor?: string;
  hairType?: string;
  src: string;
  type: 'body' | 'hair';
}

interface CharacterMeta {
  characterKey: 'hero' | 'heroSister';
  images: CharacterMetaImage[];
}

export function useCharacterImages(imageMeta: CharacterMeta) {
  const app = useWBAppContext<BookStore>()

  // hero
  if (imageMeta.characterKey === 'hero') {
    const { heroBodyColor, heroHairColor, heroHairType } = app.getAppStore()

    const bodyMeta = imageMeta.images.find((img) => {
      return img.type === 'body' && img.bodyColor === heroBodyColor
    })

    const hairMeta = imageMeta.images.find((img) => {
      return img.type === 'hair' && img.hairColor === heroHairColor && img.hairType === heroHairType
    })

    asserts.isDefined(bodyMeta, 'bodyMeta')
    asserts.isDefined(hairMeta, 'hairMeta')

    return (
      <div>
        <ImageFullSize src={bodyMeta.src} />
        <ImageFullSize src={hairMeta.src} />
      </div>
    )
  }

  // hero sister
  if (imageMeta.characterKey === 'heroSister') {
    const { heroSisterBodyColor, heroSisterHairColor, heroSisterHairType } = app.getAppStore()

    const bodyMeta = imageMeta.images.find((img) => {
      return img.type === 'body' && img.bodyColor === heroSisterBodyColor
    })

    const hairMeta = imageMeta.images.find((img) => {
      return img.type === 'hair' && img.hairColor === heroSisterHairColor && img.hairType === heroSisterHairType
    })

    asserts.isDefined(bodyMeta, 'bodyMeta')
    asserts.isDefined(hairMeta, 'hairMeta')

    return (
      <div>
        <ImageFullSize src={bodyMeta.src} />
        <ImageFullSize src={hairMeta.src} />
      </div>
    )
  }

  throw new Error(`useCharacterImages: characterKey ${imageMeta.characterKey} invalid`)
}
