import { useCallback } from 'react'
import { useStateWithGetter } from './useStateWithGetter'

export function useStateWithGetterObject<State>(initialValue?: State) {
  const [value, setValue, getValue] = useStateWithGetter<State>(initialValue)

  const setObject = useCallback((nextValue) => {
    setValue((val) => {
      const finalValue = typeof nextValue === 'function'
        ? { ...val, ...nextValue(val) }
        : { ...val, ...nextValue }

      return finalValue
    })
  }, [setValue])

  return [value, setObject, getValue] as [typeof value, typeof setValue, typeof getValue]
}
