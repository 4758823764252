import { useWBAppTempleDuCiel } from '../hooks/useWBAppTempleDuCiel'
import { WBObjectModel, WBObjectModelDefaultConfig, useWBSceneContext, useOnEntered } from 'src/WBApp/WBAppCore'
import * as asserts from 'src/utils/asserts'

interface Data {
  JSONData: {
    src: string;
  };
}

export type SceneLastChoicePropsModel = WBObjectModel<'SceneLastChoiceProps', Data>

export const sceneLastChoicePropsConfig: WBObjectModelDefaultConfig<Data> = {}

export function SceneLastChoiceProps() {
  const app = useWBAppTempleDuCiel()
  const sceneContext = useWBSceneContext()
  const sceneId = sceneContext.model.id
  const plainScene = app.extraData.screens.find((screen) => screen.id === sceneId)
  const nodeIndex = plainScene?.node.index

  useOnEntered(() => {
    // TODO

    asserts.isDefined(nodeIndex)

    const lampeId = '13183731-2c5c-473c-8ad4-b512820d5922'
    const cordeId = 'c1b8e4bd-e306-40ee-ad14-bbcf26f3c237'

    const nodesWithLampeOnly = [16, 22]
    const nodesWithCordeOny = [18, 21]
    const nodesWithBoth = [20]

    // Le lecteur décide de finalement prendre la torche
    if (nodesWithLampeOnly.includes(nodeIndex)) {
      app.setAppStore((store) => ({
        heroProps: [...store.heroProps, lampeId],
      }))
    }

    // Le lecteur décide de finalement prendre la corde
    if (nodesWithCordeOny.includes(nodeIndex)) {
      app.setAppStore((store) => ({
        heroProps: [...store.heroProps, cordeId],
      }))
    }

    // Le lecteur décide de finalement prendre la corde et la lampe
    if (nodesWithBoth.includes(nodeIndex)) {
      app.setAppStore((store) => ({
        heroProps: [...store.heroProps, lampeId, cordeId],
      }))
    }
  })

  return null
}
