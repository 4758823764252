import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Text as TextBase } from 'src/TempleDuCiel/components/Text'
import { useTextAsStr } from 'src/TempleDuCiel/hooks/useText'
import { useWBAppTempleDuCiel } from 'src/TempleDuCiel/hooks/useWBAppTempleDuCiel'
import { WBObjectModel, WBObjectModelDefaultConfig, useWBObjectContext } from 'src/WBApp/WBAppCore'

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 50px;
  margin: 100px auto 0 auto;
  align-items: center;
  justify-content: center;
`

const SkillItem = styled.div`
  padding: 20px;
  background-color: #ccc;
  color: white;
  margin: 20px;
  cursor: pointer;
  border-radius: 10px;
`

const Text = styled(TextBase)`
  max-width: 800px;
  margin: 0 auto;
  padding-top: 100px;
`

const SkillIntoText = styled.span`
  font-weight: bold;
`

interface Data {
  text: object;
}

export type TextSkillsModel = WBObjectModel<'TextSkills', Data>

export const textSkillsConfig: WBObjectModelDefaultConfig<Data> = {}

const skillsList = [
  'danser et faire le pitre',
  'siffler',
  'jongler',
  'faire des ombres chinoises',
  'viser',
]

export function TextSkills() {
  const app = useWBAppTempleDuCiel()
  const object = useWBObjectContext<TextSkillsModel>()
  const { allowPushScene } = app.getAppState()
  const { heroSkills } = app.getAppStore()
  const firstSkill = heroSkills[0] || '...'
  const secondSkill = heroSkills[1] || '...'

  const text = useTextAsStr(object.data.text)
    .replace('[SKILL_1]', firstSkill)
    .replace('[SKILL_2]', secondSkill)

  const onSelectSkill = useCallback((skillName) => {
    app.setAppStore((store) => ({
      heroSkills: [
        ...store.heroSkills,
        skillName,
      ],
    }))
  }, [app])

  useEffect(() => {
    if (!allowPushScene && heroSkills.length === 2) {
      app.setAppState({ allowPushScene: true })
    }
  }, [app, heroSkills, allowPushScene])

  return (
    <div>
      <Text>
        {text}
      </Text>
      {heroSkills.length < 2 && (
        <SkillsContainer>
          {skillsList.filter((skillName) => !heroSkills.some((_) => _ === skillName)).map((skillName) => (
            <SkillItem key={skillName} onClick={() => onSelectSkill(skillName)}>
              {skillName}
            </SkillItem>
          ))}
        </SkillsContainer>
      )}
      <Text style={{ textAlign: 'center' }}>Alors tu te dis que cela compense bien ton vertige.
      </Text>
    </div>
  )
}
