import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: 50px 0;
`

const PaletteItem = styled.div<{ color: string; isSelected: boolean; }>`
  width: 100px;
  height: 50px;
  margin: 10px;
  border-radius: 100%;
  background-color: ${(props) => props.color};
  transform: rotateZ(-30deg);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border: solid 5px ${(props) => (props.isSelected ? '#ccc' : 'transparent')};
`

interface PaletteListProps<TColor> {
  colors: TColor;
  onChange: (val: keyof TColor) => void;
  value: keyof TColor;
}

export function PaletteColor<TColor extends { [key: string]: string; }>(props: PaletteListProps<TColor>) {
  const { value, onChange, colors } = props

  return (
    <Container>
      {Object.entries(colors).map(([colorKey, colorValue]) => (
        <PaletteItem
          key={colorKey}
          color={colorValue}
          isSelected={value === colorKey}
          onClick={() => onChange(colorKey)}
        />
      ))}
    </Container>
  )
}
