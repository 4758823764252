import { useEffect } from 'react'
import { usePrevious } from 'src/hooks/usePrevious'
import { useWBSceneContext } from './context'

export function useOnExisting(fn: () => void) {
  const { isExiting } = useWBSceneContext()
  const prevIsExiting = usePrevious(isExiting)

  useEffect(() => {
    if (!prevIsExiting && isExiting) {
      fn()
    }
  }, [prevIsExiting, isExiting, fn])
}

export function useOnEntered(fn: () => void) {
  const { isEntered } = useWBSceneContext()
  const prevIsEntered = usePrevious(isEntered)

  useEffect(() => {
    if (!prevIsEntered && isEntered) {
      fn()
    }
  }, [prevIsEntered, isEntered, fn])
}
