/* eslint-disable react/no-danger */
/* eslint-disable import/no-default-export */
import React from 'react'
import styled from 'styled-components'
import { Text as TextBase } from 'src/TempleDuCiel/components/Text'
import { useText } from 'src/TempleDuCiel/hooks/useText'
import { updatePosSizeRelativeToParent } from 'src/TempleDuCiel/utils/updatePosSizeRelativeToParent'
import { Constraints, Pos, Size } from 'src/TempleDuCiel/utils/updatePosSizeRelativeToParent.types'
import {
  WBObjectModel,
  useWBObjectContext,
  WBObjectModelDefaultConfig,
  useWBAppContext,
} from 'src/WBApp/WBAppCore'
import {
  stateTransitions,
  CSSTransitionTimeout,
} from 'src/WBApp/WBAppWeb'

interface Data {
  alignH: React.CSSProperties['textAlign'];
  color?: string;
  constraints: Constraints;
  fontSize: number;
  pos: Pos;
  size: Size;
  text: string;
}

export type TextModel = WBObjectModel<'Text', Data>

export const textConfig: WBObjectModelDefaultConfig<Data> = {
  transitionTimeout: 0,
}

const TextContainer = styled(TextBase)`
  position: absolute;
  transition: all ${CSSTransitionTimeout()};
  box-sizing: border-box;

  ${stateTransitions.notAppeared} {
    opacity: 0;
  }

  ${stateTransitions.appear} {
    opacity: 1;
  }

  ${stateTransitions.disappear} {
    opacity: 0;
  }
`

export function Text() {
  const app = useWBAppContext()
  const object = useWBObjectContext<TextModel>()
  const { data } = object
  const text = useText(data.text)

  const { pos, size } = updatePosSizeRelativeToParent(
    {
      width: 500,
      height: 500,
    },
    app.appSize,
    data.pos,
    data.size,
    data.constraints,
  )

  return (
    <TextContainer
      style={{
        left: pos.x,
        top: pos.y,
        ...size,
        textAlign: data.alignH,
        color: data.color,
        fontSize: data.fontSize,
      }}
    >
      {text}
    </TextContainer>
  )
}
