import { useWindowSize } from '@react-hook/window-size'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { WBAppWeb, WBAppWebContainer } from 'src/WBApp/WBAppWeb'
import { BookStore } from './BookStore'
import { SettingsModal } from './SettingsModal'
import { objects } from './objects'
import { useStory } from './useStory'

const AppContainer = styled(WBAppWebContainer)`
  font-family: Helvetica;
  background-color: white;
  & > * {
    user-select: none;
  }

  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AppInnerContainer = styled.div`
  width: 1024px;
  height: 768px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
`

const defaultStore: Partial<BookStore> = {
  heroBodyColor: 'light',
  heroHairType: 'longWavy',
  heroHairColor: 'blond',
  heroSisterHairType: 'longWavy',
  heroSisterHairColor: 'blond',
  heroSisterBodyColor: 'medium',
  heroSkills: [],
  heroName: 'Max',
  heroSisterName: 'Lila',
  heroProps: ['16b8025f-0759-4e3b-8454-cf76af92bb33'],
}

function getInitialStore() {
  const storeStr = localStorage.getItem('WBStore')
  if (storeStr) {
    try {
      return JSON.parse(storeStr)
    } catch (e) {
      // nothing
    }
  }

  return defaultStore
}

export function TempleDuCiel() {
  const [screenWidth, screenHeight] = useWindowSize()
  const width = 1024
  const height = 768

  const offsetTop = (screenHeight - height) / 2
  const offsetLeft = (screenWidth - width) / 2

  const size = useMemo(() => ({ width, height }), [width, height])
  const offset = useMemo(() => ({
    top: offsetTop,
    left: offsetLeft,
  }), [offsetTop, offsetLeft])

  const { story, fetchData: reloadData, extraData } = useStory()

  if (!story) return <div style={{ color: 'white' }}>Loading...</div>

  return (
    <AppContainer>
      <AppInnerContainer>
        <WBAppWeb
          childrenAfterScenes={<SettingsModal reloadData={reloadData} />}
          defaultStore={getInitialStore()}
          extraData={extraData}
          objects={objects}
          offset={offset}
          size={size}
          story={story}
          window={window}
        />
      </AppInnerContainer>
    </AppContainer>
  )
}
