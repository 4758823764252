/* eslint-disable import/no-default-export */
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import { useWBAppTempleDuCiel } from 'src/TempleDuCiel/hooks/useWBAppTempleDuCiel'
import { getConditionValue } from 'src/TempleDuCiel/utils/getConditionValue'
import { WBObjectModel, useWBObjectContext, WBObjectModelDefaultConfig } from 'src/WBApp/WBAppCore'
import { Container } from './NextButton.styles'

interface Data {
  nexts: {
    conditionId: string;
    sceneId: string;
  }[];
}

export type NextButtonModel = WBObjectModel<'NextButton', Data>

export const nextButtonConfig: WBObjectModelDefaultConfig<Data> = {
  transitionTimeout: 1500,
}

export function NextButton() {
  const app = useWBAppTempleDuCiel()
  const object = useWBObjectContext<NextButtonModel>()
  const { allowPushScene } = app.getAppState()

  const onClick = useCallback(() => {
    const nextScreenIsUniq = object.data.nexts.length === 1

    if (nextScreenIsUniq) {
      app.pushScene(object.data.nexts[0].sceneId)
    } else {
      const next = object.data.nexts.find((_) => getConditionValue(app, _.conditionId))
      if (!next) {
        throw new Error('Condition Error')
      }

      app.pushScene(next.sceneId)
    }
  }, [app, object])

  return (
    <Container onClick={onClick} show={allowPushScene}>
      <FontAwesomeIcon icon={faArrowRight} />
    </Container>
  )
}
