import { useWBAppTempleDuCiel } from 'src/TempleDuCiel/hooks/useWBAppTempleDuCiel'
import { StoryCondition } from 'src/TempleDuCiel/useStory'
import * as asserts from 'src/utils/asserts'

export function getConditionValue(app: ReturnType<typeof useWBAppTempleDuCiel>, conditionOrConditionId: string | StoryCondition['value']): boolean {
  let condition: StoryCondition['value']

  if (typeof conditionOrConditionId === 'string') {
    const plainCondition = app.extraData.conditions.find(({ id }) => id === conditionOrConditionId)
    asserts.isDefined(plainCondition)
    condition = plainCondition.value
  } else {
    condition = conditionOrConditionId
  }

  asserts.isDefined(condition)

  if (condition.type.startsWith('condition:')) {
    asserts.isArrayOfObject(condition.value, ['type', 'value'])
    if (condition.type.endsWith(':and')) {
      return condition.value.every((cond) => getConditionValue(app, cond))
    }

    if (condition.type.endsWith(':or')) {
      return condition.value.some((cond) => getConditionValue(app, cond))
    }

    throw new Error('getConditionValue operator error: condition:')
  }

  if (condition.type.startsWith('inventory:')) {
    // const { operator, src, value } = condition
    // @ts-ignore
    const storeSrc = app.getAppStore().heroProps

    if (condition.type.endsWith(':did')) {
      asserts.isString(condition.value)
      return storeSrc.includes(condition.value)
    }

    if (condition.type.endsWith(':didnt')) {
      asserts.isString(condition.value)
      return !storeSrc.includes(condition.value)
    }

    throw new Error('getConditionValue operator error: inventory:')
  }

  throw new Error('getConditionValue type error')
}
