// eslint-disable-next-line
type AnyCantFix = any

export function isString(val: AnyCantFix, msg?: string): asserts val is string {
  if (typeof val !== 'string') {
    throw new Error(`Assertion Error: string. ${msg}`)
  }
}

export function isArrayOfString(val: AnyCantFix, msg?: string): asserts val is string[] {
  if (val.some((item: string) => typeof item !== 'string')) {
    throw new Error(`Assertion Error: array of string. ${msg}`)
  }
}

export function isArrayOfObject<T extends string>(val: AnyCantFix, keys: T[], msg?: string): asserts val is { [key in T]: AnyCantFix }[] {
  // @ts-ignore
  if (val.some((item: T) => keys.some((key) => item[key] === undefined))) {
    throw new Error(`Assertion Error: array of object. ${msg}`)
  }
}

export function isNumber(val: AnyCantFix, msg?: string): asserts val is number {
  if (typeof val !== 'number') {
    throw new Error(`Assertion Error: number. ${msg}`)
  }
}

export function isBoolean(val: AnyCantFix, msg?: string): asserts val is boolean {
  if (typeof val !== 'boolean') {
    throw new Error(`Assertion Error: boolean. ${msg}`)
  }
}

export function isFunction(val: AnyCantFix, msg?: string): asserts val is Function {
  if (typeof val !== 'function') {
    throw new Error(`Assertion Error: function. ${msg}`)
  }
}

export function isDefined<T>(val: T, msg?: string): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(`Assertion Error: undefined. ${msg}`)
  }
}
