import React from 'react'
import { useWBAppContext, WBAppContextValue } from 'src/WBApp/WBAppCore'

export function getTextAsStr(content: any, app: WBAppContextValue) {
  let plainText = ''

  if (content) {
    content.document.nodes.forEach((node: any) => {
      if (node.type === 'paragraph') {
        node.nodes.forEach((paragraphNode: any) => {
          if (paragraphNode.object === 'text') {
            plainText += paragraphNode.text
          }

          if (
            paragraphNode.object === 'inline'
            && paragraphNode.type === 'userMention'
          ) {
            // @ts-ignore
            const characterName = app.getAppStore()[paragraphNode.data.key]
            plainText += characterName
          }
        })

        plainText += '\n'
      }
    })
  }

  return plainText
}

export function getText(content: any, app: WBAppContextValue) {
  const texts: JSX.Element[] = []

  if (content) {
    content.document.nodes.forEach((node: any, documentNodeIndex: number) => {
      if (node.type === 'paragraph') {
        node.nodes.forEach((paragraphNode: any, nodeNodeIndex: number) => {
          const key = `${documentNodeIndex}-${nodeNodeIndex}`
          if (paragraphNode.object === 'text') {
            texts.push(<span key={key}>{paragraphNode.text}</span>)
          }

          if (
            paragraphNode.object === 'inline'
            && paragraphNode.type === 'userMention'
          ) {
            // @ts-ignore
            const characterName = app.getAppStore()[paragraphNode.data.key]
            texts.push(<span key={key}>{characterName}</span>)
          }
        })

        // eslint-disable-next-line react/no-array-index-key
        texts.push(<br key={`br-${documentNodeIndex}`} />)
      }
    })
  }

  return texts
}

export function useText(content: any) {
  const app = useWBAppContext()
  return getText(content, app)
}

export function useTextAsStr(content: any) {
  const app = useWBAppContext()
  return getTextAsStr(content, app)
}
