import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import styled from 'styled-components'
// import { BagItemKey } from 'src/TempleDuCiel/BookStore'

const UI = {
  Container: styled.div`
    display: flex;
    margin: 10px;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
  `,
  CheckBoxContainer: styled.div`
    border: solid 2px black;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
}

interface CheckBoxProps {
  color: 'initial' | 'green';
  isSelected: boolean;
}

function CheckBox(props: CheckBoxProps) {
  const { isSelected, color } = props
  return (
    <UI.CheckBoxContainer>
      {isSelected && <FontAwesomeIcon icon={faCheck} style={{ fontSize: 30, color }} />}
    </UI.CheckBoxContainer>
  )
}

interface BagItemProps {
  isOptional: boolean;
  isSelected: boolean;
  itemId: string;
  name: string;
  onClick: (itemId: string) => void;
  size: number;
}

export function BagItem(props: BagItemProps) {
  const { name, size, isSelected, onClick, itemId, isOptional } = props

  const onClickItem = useCallback(() => {
    onClick(itemId)
  }, [onClick, itemId])

  return (
    <UI.Container onClick={onClickItem}>
      <CheckBox color={isOptional ? 'green' : 'initial'} isSelected={isSelected} />
      <div>{name} (taille {size})</div>
    </UI.Container>
  )
}
