import { useState, useEffect, useCallback } from 'react'
import { WBStory } from 'src/WBApp/WBAppCore'
import * as asserts from 'src/utils/asserts'
import plainBookStoryJSON from './story.json'
import plainBookTextsJSON from './texts.json'

console.log('plainBookStoryJSON', plainBookStoryJSON)

const BOOK_ID = 'e38a945b-a8c6-49eb-bf1d-00d1472f26ff'
const ACCESS_TOKEN = 'KVhpRaLREACqFZAucBE3iNeAGamRomG80NCEC0RH59x2EFP41tm4e5qVdv583OyW'

interface FileBase {
  link: string;
  // type: string;
  versionDate: string;
}

interface FileStory extends FileBase {
  type: 'story';
}

interface FileText extends FileBase {
  gender: 'm' | 'f';
  lang: string;
  type: 'text';
}

type File = FileStory | FileText

interface Data {
  files: File[];
}

export interface StoryCondition {
  description: string;
  id: string;
  value: {
    type: string;
    value: string | StoryCondition['value'][];
  };
}

interface StoryComponentBase {
  JSONData: object;
  format: string;
  name: string;
  type: string;
}

interface StoryComponentText extends StoryComponentBase {
  alignH: string;
  alignV: string;
  color: any;
  constraints: any;
  fontSize: number;
  format: 'Text';
  pos: any;
  size: any;
  textId: string;
}

interface StoryComponentNextButton extends StoryComponentBase {
  format: 'NextButton';
  next: {
    conditionId?: string;
    screenId: string | null;
  }[];
}

interface StoryComponentCharacterName extends StoryComponentBase {
  characterId: string;
  format: 'CharacterName';
}

interface StoryComponentCustom extends StoryComponentBase {
  format: 'Custom';
}

type StoryComponent =
  | StoryComponentText
  | StoryComponentNextButton
  | StoryComponentCharacterName
  | StoryComponentCustom

export interface PlainStory {
  characters: {
    description: string;
    id: string;
    key: string;
    name: string;
  }[];
  conditions: StoryCondition[];
  defaultGender: 'm' | 'f';
  defaultLanguage: 'fr';
  props: {
    id: string;
    name: string;
    size: number;
  }[];
  screens: {
    choices: {
      conditionId: string;
      next: {
        screenId: string;
      }[];
      textId: string;
    }[];
    components: StoryComponent[];
    id: string;
    index: number;
    node: {
      index: number;
    };
    templateId: string;
    templateIndex: number;
  }[];
  startId: string;
}

interface PlainTexts {
  items: {
    id: string;
    plainText: string;
    value: any;
  }[];
}

function fetchWBServer(url: string) {
  return fetch(`${url}?access_token=${ACCESS_TOKEN}`).then((res) => res.json())
}

interface UseStoryOutput {
  extraData?: PlainStory;
  fetchData: () => void;
  story: WBStory | null;
}

export function useStory(): UseStoryOutput {
  // @ts-ignore
  const [plainBookStory, setPlainBookStory] = useState<PlainStory | null>(plainBookStoryJSON)
  const [plainBookTexts, setPlainBookTexts] = useState<PlainTexts | null>(plainBookTextsJSON)

  const fetchData = useCallback(() => {
    // const host = `${window.location.protocol}//${window.location.hostname}`
    // fetchWBServer(`${host}:3000/api/books/${BOOK_ID}/export`)
    //   .then((res: Data) => {
    //     const fileStory = res.files.find((file) => file.type === 'story')
    //     const fileTextM = res.files.find((file) => file.type === 'text' && file.gender === 'm')

    //     asserts.isDefined(fileStory)
    //     asserts.isDefined(fileTextM)

    //     fetchWBServer(fileStory?.link)
    //       .then((story) => {
    //         setPlainBookStory(story)
    //       })

    //     fetchWBServer(fileTextM?.link)
    //       .then((texts) => {
    //         setPlainBookTexts(texts)
    //       })
    //   })
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (!plainBookStory || !plainBookTexts) {
    return {
      story: null,
      fetchData,
    }
  }

  function getText(textId: string, debugNodeIndex: number) {
    asserts.isString(textId, `text id ${textId} is undefined on node ${debugNodeIndex}`)
    asserts.isDefined(plainBookTexts)
    asserts.isDefined(plainBookStory)

    const text = plainBookTexts.items.find(({ id }) => id === textId)

    asserts.isDefined(text)

    text.value.document.nodes.forEach((node: any) => {
      if (node.type === 'paragraph') {
        node.nodes.forEach((paragraphNode: any) => {
          if (
            paragraphNode.object === 'inline'
            && paragraphNode.type === 'userMention'
          ) {
            // @ts-ignore
            const character = plainBookStory.characters.find(({ id }) => id === paragraphNode.data.userId)
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            paragraphNode.data.key = character?.key
          }
        })
      }
    })

    return text.value
  }

  const scenes: WBStory['scenes'] = plainBookStory.screens
    // @ts-ignore
    .map((screen): WBStory['scenes'][0] => {
      const objects: WBStory['scenes'][0]['objects'] = []

      //-----------------------------------------------------------
      //                        COMPONENTS
      //-----------------------------------------------------------
      // @ts-ignore
      screen.components.forEach((component) => {
        //-----------------------------------------------------------
        //                        TEXTS
        //-----------------------------------------------------------
        if (component.format === 'Text') {
          const object = {
            type: component.type,
            data: {
              text: getText(component.textId, screen.node.index),
              JSONData: component.JSONData,
              pos: component.pos,
              size: component.size,
              constraints: component.constraints,
              color: component.color,
              fontSize: component.fontSize,
              alignH: component.alignH,
              alignV: component.alignV,
            },
          }

          objects.push(object)
        }

        //-----------------------------------------------------------
        //                        NextButton
        //-----------------------------------------------------------
        if (component.format === 'NextButton') {
          const object = {
            type: component.type,
            data: {
              // nextSceneId: component.next && component.next[0].screenId,
              // @ts-ignore
              nexts: (component.next || []).map((item) => ({
                sceneId: item.screenId,
                conditionId: item.conditionId,
              })),
            },
          }

          objects.push(object)
        }

        //-----------------------------------------------------------
        //                        CharacterName
        //-----------------------------------------------------------
        if (component.format === 'CharacterName') {
          // @ts-ignore
          const character = plainBookStory.characters.find(({ id }) => id === component.characterId)
          const object = {
            type: component.type,
            data: {
              characterKey: character?.key,
            },
          }

          objects.push(object)
        }

        //-----------------------------------------------------------
        //                        Custom
        //-----------------------------------------------------------
        if (component.format === 'Custom') {
          if (component.type === 'WEB_CUSTOM_SCENE') {
            // @ts-ignore
            // if (commonObjects[component.name]) {
            //   const object = {
            //     type: component.name,
            //     data: {
            //       JSONData: component.JSONData,
            //     },
            //   }
            //   objects.push(object)
            // }
          } else {
            const object = {
              type: component.type,
              data: {
                JSONData: component.JSONData,
              },
            }
            objects.push(object)
          }
        }
      })

      //-----------------------------------------------------------
      //                        CHOICE
      //-----------------------------------------------------------
      if (screen.choices) {
        const choiceObject = {
          type: 'ChoiceButton',
          data: {
            // @ts-ignore
            choices: screen.choices.map((choice) => ({
              sceneId: choice.next[0].screenId,
              text: getText(choice.textId, screen.node.index),
            })),
          },
          transitionTimeout: 1500,
        }

        objects.push(choiceObject)
      }

      return {
        id: screen.id,
        objects,
      }
    })
    // @ts-ignore
    .reduce((a, b) => ({ ...a, [b.id]: b }), {})

  return {
    story: {
      startSceneId: plainBookStory.startId,
      defaultTransitionTime: 1000,
      scenes,
    },
    extraData: plainBookStory,
    fetchData,
  }
}
