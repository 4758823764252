import React from 'react'
import { useCharacterImages } from 'src/TempleDuCiel/hooks/useCharacterImage'
import { useImages } from 'src/TempleDuCiel/hooks/useImages'
import {
  WBObjectModel,
  WBObjectModelDefaultConfig,
  useWBObjectContext,
  useWBAppContext,
} from 'src/WBApp/WBAppCore'

interface ImageData {
  backgroundColor?: string;
  imageType: 'characterImage' | 'mergeImages';
  src: object;
}

export type ImageModel = WBObjectModel<'Image', ImageData>

export const imageConfig: WBObjectModelDefaultConfig<ImageData> = {}

const types = {
  characterImage: useCharacterImages,
  mergeImages: useImages,
}

export function Image() {
  const app = useWBAppContext()
  const object = useWBObjectContext<ImageModel>()
  const { src, imageType } = object.data
  // @ts-ignore
  const img = types[imageType](src)

  // return img
  return (
    <div style={{
      position: 'absolute',
      width: app.appSize.width,
      height: app.appSize.height,
      backgroundColor: object.data.backgroundColor,
    }}
    >
      {img}
    </div>
  )
}
