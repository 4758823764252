import styled from 'styled-components'

export const WBAppWebContainer = styled.div`
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  touch-action: manipulation;
  /* transform: scale(0.5); */
`
