/* eslint-disable max-len */
import React from 'react'
import styled from 'styled-components'

// short
import { HairType, HairColor } from 'src/TempleDuCiel/BookStore'
import hairShortStraightBlond from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0000_HAIR-TAGS-character-hero_hair-short-straight_hair-color-blond.png'
import hairShortStraightRed from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0001_HAIR-TAGS-character-hero_hair-short-straight_hair-color-red.png'
import hairShortStraightBlack from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0002_HAIR-TAGS-character-hero_hair-short-straight_hair-color-black.png'
import hairShortWavyBlond from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0003_HAIR-TAGS-character-hero_hair-short-wavy_hair-color-blond.png'
import hairShortWavyRed from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0004_HAIR-TAGS-character-hero_hair-short-wavy_hair-color-red.png'
import hairShortWavyBlack from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0005_HAIR-TAGS-character-hero_hair-short-wavy_hair-color-black.png'
import hairShortCurlyBlond from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0006_HAIR-TAGS-character-hero_hair-short-curly_hair-color-blond.png'
import hairShortCurlyRed from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0007_HAIR-TAGS-character-hero_hair-short-curly_hair-color-red.png'
import hairShortCurlyBlack from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0008_HAIR-TAGS-character-hero_hair-short-curly_hair-color-black.png'

// long
import hairLongStraightBlond from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0009_HAIR-TAGS-character-hero_hair-long-straight_hair-color-blond.png'
import hairLongStraightRed from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0010_HAIR-TAGS-character-hero_hair-long-straight_hair-color-red.png'
import hairLongStraightBlack from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0011_HAIR-TAGS-character-hero_hair-long-straight_hair-color-black.png'
import hairLongWavyBlond from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0012_HAIR-TAGS-character-hero_hair-long-wavy_hair-color-blond.png'
import hairLongWavyRed from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0013_HAIR-TAGS-character-hero_hair-long-wavy_hair-color-red.png'
import hairLongWavyBlack from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0014_HAIR-TAGS-character-hero_hair-long-wavy_hair-color-black.png'
import hairLongCurlyBlond from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0015_HAIR-TAGS-character-hero_hair-long-curly_hair-color-blond.png'
import hairLongCurlyRed from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0016_HAIR-TAGS-character-hero_hair-long-curly_hair-color-red.png'
import hairLongCurlyBlack from 'src/TempleDuCiel/assets/003-2bis/003-2bis_0017_HAIR-TAGS-character-hero_hair-long-curly_hair-color-black.png'

function camelize(str: string) {
  return str[0].toUpperCase() + str.substr(1, str.length - 1)
}

const hairs = {
  hairShortStraightBlond,
  hairShortStraightRed,
  hairShortStraightBlack,
  hairShortWavyBlond,
  hairShortWavyRed,
  hairShortWavyBlack,
  hairShortCurlyBlond,
  hairShortCurlyRed,
  hairShortCurlyBlack,
  hairLongStraightBlond,
  hairLongStraightRed,
  hairLongStraightBlack,
  hairLongWavyBlond,
  hairLongWavyRed,
  hairLongWavyBlack,
  hairLongCurlyBlond,
  hairLongCurlyRed,
  hairLongCurlyBlack,
}

const Image = styled.img`
  position: absolute;
`

interface ImageHairProps {
  hairColor: HairColor;
  hairType: HairType;
}

export const hairColors = {
  blond: '#da9322',
  red: '#a63d13',
  black: '#000',
}

export function ImageHair(props: ImageHairProps) {
  const { hairType, hairColor } = props

  const hairKey = `hair${camelize(hairType)}${camelize(hairColor)}` as keyof typeof hairs
  const src = hairs[hairKey]

  return (
    <Image
      alt="body"
      src={src}
      width="421"
    />
  )
}
