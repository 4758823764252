export const stateTransitions = {
  // before start appearing
  notAppeared: `
    &.scene-appear:not(.scene-appear-active),
    .scene-appear:not(.scene-appear-active) &
  `,

  // when appearing and appeared. Usefull for simple transition like CSS left, top, etc
  appear: `
    &.scene-appear-active, &.scene-appear-done,
    .scene-appear-active &, .scene-appear-done &
  `,

  // when appearing, during the transition. Useful for keyframes transtion
  appearing: `
    &.scene-appear-active,
    .scene-appear-active &
  `,

  // when appeared, after the appearing transition
  appeared: `
    &.scene-appear-done,
    .scene-appear-done &
  `,

  // when disappearing and disappeared, so during and after the transition. Usefull for simple transition
  disappear: `
    &.scene-exit-active, &.scene-exit-done,
    .scene-exit-active &, .scene-exit-done &
  `,

  // when disappearing, during the transition. useful for keyframes transtion
  disappearing: `
    &.scene-exit-active,
    .scene-exit-active &
  `,

  // when disappeared, after the disappearing transition
  disappeared: `
    &.scene-exit-done,
    .scene-exit-done &
  `,
}
