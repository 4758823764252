import React from 'react'
import { ImageFullSize } from 'src/TempleDuCiel/components/ImageFullSize'

interface ImageMetaFile {
  src: string;
}

interface ImageMeta {
  images: ImageMetaFile[];
}

export function useImages(imageMeta: ImageMeta) {
  return imageMeta.images.map((img) => (
    <ImageFullSize key={img.src} src={img.src} />
  ))
}
