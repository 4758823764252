import React, { useState, useEffect, useMemo, useCallback, useRef, Fragment } from 'react'
import { Transition } from 'react-transition-group'
import styled from 'styled-components'
import { getTextAsStr } from 'src/TempleDuCiel/hooks/useText'
import { useWBAppContext, useWBObjectContext, WBObjectModelDefaultConfig } from 'src/WBApp/WBAppCore'
import { useMount } from 'src/hooks/useMount'
import { useStateWithGetter, Getter } from 'src/hooks/useStateWithGetter'
import { useUnmount } from 'src/hooks/useUnmount'
import { Button } from './Button'
import { ChoiceButtonModel, ChoiceButtonData } from './types'
import { useChoicePoints, useGetChoiceButtonPos, useGetChoiceCompletedCoeff, useOnChoiceCompleted } from './utils'

type TransitionState = 'entering' | 'entered'

function opacity(props: { transitionState: TransitionState; }) {
  return ['entering', 'entered'].includes(props.transitionState)
    ? 1
    : 0
}

const Background = styled.div<{ transitionState: TransitionState; }>`
  background-color: rgba(250, 250, 250, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  /* filter: blur(10px); */
  transition: all 0.5s;
  opacity: ${opacity};
`

const lineColor = '#ccc'

interface Pos {
  x: number;
  y: number;
}

interface ChoiceViewProps {
  choices: ChoiceButtonModel['data']['choices'];
  clientPos: Pos | null;
  getShowChoice: Getter<boolean>;
  onHideChoiceView: () => void;
  setClientPos: (pos: Pos | null) => void;
  showChoice: boolean;
}

const buttonSize = 50

function useInitialChoiceButtonPosCenter() {
  const app = useWBAppContext()
  return useMemo(() => {
    return {
      x: app.appSize.width / 2,
      y: app.appSize.height - 100,
    }
  }, [app])
}

function ChoiceView(props: ChoiceViewProps) {
  const {
    choices,
    onHideChoiceView,
    clientPos,
    setClientPos,
    showChoice,
    getShowChoice,
  } = props

  const appContext = useWBAppContext()
  const { appWindow } = appContext

  const isUnmount = useRef(false)

  // const getPlainTextFromSlate = useGetPlainTextFromSlate()

  const onMouseMove = useCallback((event) => {
    if (!getShowChoice()) return
    // avoid memory leak
    if (isUnmount.current) return
    setClientPos({
      x: event.clientX - appContext.appOffset.left,
      y: event.clientY - appContext.appOffset.top,
    })
  }, [getShowChoice, setClientPos, appContext.appOffset])

  const onTouchMove = useCallback((event) => {
    if (!getShowChoice()) return
    // avoid memory leak
    if (isUnmount.current) return

    setClientPos({
      x: event.changedTouches[0].clientX - appContext.appOffset.left,
      y: event.changedTouches[0].clientY - appContext.appOffset.top,
    })
  }, [getShowChoice, setClientPos, appContext.appOffset])

  const onMouseUp = useCallback(() => {
    if (!getShowChoice()) return
    setClientPos(null)
    onHideChoiceView()
    // eslint-disable-next-line
  }, [onHideChoiceView, setClientPos])

  useMount(() => {
    appWindow.addEventListener('mousemove', onMouseMove)
    appWindow.addEventListener('mouseup', onMouseUp)
    appWindow.addEventListener('touchmove', onTouchMove)
    appWindow.addEventListener('touchend', onMouseUp)
  })

  useUnmount(() => {
    isUnmount.current = true
    appWindow.removeEventListener('mousemove', onMouseMove)
    appWindow.removeEventListener('mouseup', onMouseUp)
    appWindow.removeEventListener('touchmove', onTouchMove)
    appWindow.removeEventListener('touchend', onMouseUp)
  })

  const points = useChoicePoints(choices.length)
  const initialChoiceButtonPos = useInitialChoiceButtonPosCenter()

  const { completedCoeff, currentChoiceIndex } = useGetChoiceCompletedCoeff(initialChoiceButtonPos, clientPos, points)

  return (
    <Transition
      in={showChoice}
      timeout={500}
    >
      {(state: TransitionState) => (
        <Background transitionState={state}>
          {choices.map((choice, index) => {
            const point = points[index]
            // const plainText = getPlainTextFromSlate(choice.text)
            // import { useText } from 'src/TempleDuCiel/hooks/useText'
            // const plainText = choice.text
            const plainText = getTextAsStr(choice.text, appContext)

            return (
              <Fragment key={plainText}>
                <svg style={{ position: 'absolute' }} {...appContext.appSize}>
                  <line
                    stroke={lineColor}
                    strokeWidth={8}
                    x1={point.x}
                    x2={initialChoiceButtonPos.x}
                    y1={point.y}
                    y2={initialChoiceButtonPos.y}
                  />
                  <circle cx={point.x} cy={point.y} fill={lineColor} r={10} />
                </svg>
                <div
                  style={{
                    position: 'absolute',
                    width: 200,
                    left: point.x - (200 / 2),
                    bottom: appContext.appSize.height - point.y + 50,
                    textAlign: 'center',
                    transform: index === currentChoiceIndex ? `scale(${1 + 0.5 * completedCoeff})` : undefined,
                  }}
                >
                  {plainText}
                </div>
              </Fragment>
            )
          })}
          <svg {...appContext.appSize}>
            <circle
              cx={initialChoiceButtonPos.x}
              cy={initialChoiceButtonPos.y}
              fill={lineColor}
              r={20}
            />
          </svg>
        </Background>
      )}
    </Transition>
  )
}

export function ChoiceButton() {
  const object = useWBObjectContext<ChoiceButtonModel>()
  const [showChoice, setShowChoice, getShowChoice] = useStateWithGetter(false)
  const [clientPos, setClientPos] = useState<Pos | null>(null)
  const choiceCompleted = useRef(false)

  const initialChoiceButtonPos = useInitialChoiceButtonPosCenter()
  const onChoiceCompleted = useOnChoiceCompleted()

  const { choices } = object.data

  const points = useChoicePoints(choices.length)

  const { completedCoeff, currentChoiceIndex } = useGetChoiceCompletedCoeff(initialChoiceButtonPos, clientPos, points)

  useEffect(() => {
    if (currentChoiceIndex !== null && !choiceCompleted.current && completedCoeff > 0.95) {
      choiceCompleted.current = true
      onChoiceCompleted(currentChoiceIndex)
    }
  }, [completedCoeff, currentChoiceIndex, onChoiceCompleted])

  const currentChoicePoint = typeof currentChoiceIndex === 'number' ? points[currentChoiceIndex] : null

  const nextPos = useGetChoiceButtonPos(completedCoeff, initialChoiceButtonPos, currentChoicePoint)

  const translateX = nextPos && nextPos.x - initialChoiceButtonPos.x
  const translateY = nextPos && nextPos.y - initialChoiceButtonPos.y

  const buttonTransform = translateX && translateY && (
    nextPos && `translate(${translateX}px, ${translateY}px)`
  )

  const onDragStart = useCallback(() => setShowChoice(true), [setShowChoice])

  const onHideChoiceView = useCallback(() => setShowChoice(false), [setShowChoice])

  return (
    <div>
      <ChoiceView
        choices={choices}
        clientPos={clientPos}
        getShowChoice={getShowChoice}
        onHideChoiceView={onHideChoiceView}
        setClientPos={setClientPos}
        showChoice={showChoice}
      />
      <Button
        onMouseDown={onDragStart}
        onTouchStart={onDragStart}
        style={{
          position: 'absolute',
          transform: buttonTransform || undefined,
          zIndex: 1,
          left: initialChoiceButtonPos.x - (buttonSize / 2),
          top: initialChoiceButtonPos.y - (buttonSize / 2),
        }}
      />
    </div>
  )
}

export const choiceButtonConfig: WBObjectModelDefaultConfig<ChoiceButtonData> = {
  transitionTimeout: 1500,
}
