import React, { useMemo } from 'react'
import { WBObjectModel } from './WBApp.types'
import { useWBAppContext, ObjectContext } from './context'

interface WBObjectProps {
  model: WBObjectModel;
}

export function WBObject(props: WBObjectProps) {
  const { model } = props
  const appContext = useWBAppContext()

  const objectContextValue = useMemo(() => ({
    ...model,
  }), [model])

  const { View } = appContext.appObjects[model.type]

  if (!View) {
    return <div>Object {model.type} is undefined</div>
  }

  return (
    <ObjectContext.Provider value={objectContextValue}>
      <View />
    </ObjectContext.Provider>
  )
}
