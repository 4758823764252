import React from 'react'
import styled from 'styled-components'
import { useWBAppContext } from 'src/WBApp/WBAppCore'

const Image = styled.img`
  position: absolute;
`

const imageSize = {
  width: 1368,
  height: 1024,
}

interface ImageFullSizeProps {
  src: string;
}

export function ImageFullSize(props: ImageFullSizeProps) {
  const app = useWBAppContext()
  const { src } = props

  const left = (app.appSize.width - imageSize.width) / 2
  const top = (app.appSize.height - imageSize.height) / 2

  return (
    <Image
      height="1024"
      src={src}
      style={{
        left,
        top,
      }}
      width="1368"
    />
  )
}
