import styled from 'styled-components'
import { CSSTransitionTimeout } from 'src/WBApp/WBAppWeb'

export const Container = styled.div<{ show: boolean; }>`
  position: absolute;
  width: 60px;
  height: 60px;
  right: 10px;
  top: calc(50% - 25px);
  background-color: #04b8ca;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > * {
    font-size: 22px;
    color: white;
  }

  /* transition timeout is declare here because is not use in transition object */
  transition: all ${CSSTransitionTimeout()};
  opacity: ${(props) => (props.show ? 1 : 0)};
`
