import React from 'react'
import assets from 'src/TempleDuCiel/assets'
import { useCharacterImages } from 'src/TempleDuCiel/hooks/useCharacterImage'
import { useWBObjectContext, WBObjectModel, WBObjectModelDefaultConfig } from 'src/WBApp/WBAppCore'
import * as asserts from 'src/utils/asserts'

interface Data {
  JSONData: {
    src: string;
  };
}

export type ImageCharacterModel = WBObjectModel<'ImageCharacter', Data>

export const imageCharacterConfig: WBObjectModelDefaultConfig<Data> = {}

export function ImageCharacter() {
  const object = useWBObjectContext<ImageCharacterModel>()
  const { src } = object.data.JSONData
  asserts.isString(src, 'Image source of ImageCharacter is not define')
  // @ts-ignore
  const imageSrc = assets[src]
  asserts.isDefined(imageSrc, `${src}`)
  const image = useCharacterImages(imageSrc)

  return (
    <div>
      {image}
    </div>
  )
}
