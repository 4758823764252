import { Size, Pos, FlatConstraints, Constraints, ConstraintsKeyConversion } from './updatePosSizeRelativeToParent.types'

export function convert(
  prevParentLength: number,
  nextParentLength: number,
  pos: number,
  size: number,
  constraint: FlatConstraints,
): { pos: number; size: number; } {
  if (constraint === 'start') {
    return {
      pos,
      size,
    }
  }

  if (constraint === 'end') {
    const parentLengthDiff = nextParentLength - prevParentLength
    return {
      pos: pos + parentLengthDiff,
      size,
    }
  }

  if (constraint === 'start-end') {
    const parentLengthDiff = nextParentLength - prevParentLength
    return {
      pos,
      size: size + parentLengthDiff,
    }
  }

  if (constraint === 'scale') {
    const coeff = nextParentLength / prevParentLength
    return {
      pos: pos * coeff,
      size: size * coeff,
    }
  }

  if (constraint === 'center') {
    const prevCenter = prevParentLength / 2
    const centerOffset = pos - prevCenter
    const nextCenter = nextParentLength / 2
    return {
      pos: nextCenter + centerOffset,
      size,
    }
  }

  throw new Error('convert has a bad constraint')
}

export const constraintsKeyConversion: ConstraintsKeyConversion = {
  h: {
    left: 'start',
    right: 'end',
    'left-right': 'start-end',
    scale: 'scale',
    center: 'center',
  },
  v: {
    top: 'start',
    bottom: 'end',
    'top-bottom': 'start-end',
    scale: 'scale',
    center: 'center',
  },
}

export function updatePosSizeRelativeToParent(
  prevParentSize: Size,
  nextParentSize: Size,
  pos: Pos,
  size: Size,
  constraints: Constraints,
) {
  const { pos: x, size: width } = convert(
    prevParentSize.width,
    nextParentSize.width,
    pos.x,
    size.width,
    constraintsKeyConversion.h[constraints.h],
  )

  const { pos: y, size: height } = convert(
    prevParentSize.height,
    nextParentSize.height,
    pos.y,
    size.height,
    constraintsKeyConversion.v[constraints.v],
  )

  return {
    pos: {
      x: Math.round(x),
      y: Math.round(y),
    },
    size: {
      width: Math.round(width),
      height: Math.round(height),
    },
  }
}
