import styled from 'styled-components'
import { CSSTransitionFade } from 'src/WBApp/WBAppWeb'

export const Container = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr 100px;
  grid-template-rows: 100%;
  grid-template-areas:
    "Hero Palette .";

  ${CSSTransitionFade()}
`

export const Hero = styled.div`
  grid-area: Hero;
`

export const Palette = styled.div`
  grid-area: Palette;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
