import { faCog, faTimes, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useWBAppContext } from 'src/WBApp/WBAppCore'

const Container = styled.div`
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
`

const BoxContainer = styled.div`
  position: absolute;
  left: 15%;
  top: 15%;
  width: 70%;
  height: 70%;
  background: #fff;
  border: solid 1px #ccc;
  padding: 30px;
  box-sizing: border-box;
`

const MenuContainer = styled.div`
  position: absolute;
  display: flex;
  right: 20px;
  top: 20px;
`

const Icon = styled(FontAwesomeIcon)`
  right: 20px;
  top: 20px;
  font-size: 30px;
  cursor: pointer;
`

interface SettingsModalProps {
  reloadData: () => void;
}

export function SettingsModal(props: SettingsModalProps) {
  const { reloadData } = props
  const [isOpen, setIsOpen] = useState(false)
  const app = useWBAppContext()

  const save = useCallback(() => {
    localStorage.setItem('WBStore', JSON.stringify(app.getAppStore()))
  }, [app])

  const reset = useCallback(() => {
    localStorage.setItem('WBStore', '')
  }, [])

  if (!isOpen) {
    return (
      <MenuContainer>
        <Icon
          icon={faRedoAlt}
          onClick={reloadData}
          style={{ marginRight: 10 }}
        />
        <Icon
          icon={faCog}
          onClick={() => setIsOpen(true)}
        />
      </MenuContainer>
    )
  }

  return (
    <Container>
      <BoxContainer>
        <Icon icon={faTimes} onClick={() => setIsOpen(false)} style={{ position: 'absolute' }} />
        <button onClick={reloadData} type="button">Mettre à jour</button>
        <button onClick={save} type="button">Sauvegarder</button>
        <button onClick={reset} type="button">Recommencer</button>
      </BoxContainer>
    </Container>
  )
}
