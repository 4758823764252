import styled from 'styled-components'
import { stateTransitions, CSSTransitionTimeout } from 'src/WBApp/WBAppWeb'

const buttonSize = 50

export const Button = styled.div`
  position: relative;
  display: flex;
  height: ${buttonSize}px;
  width: ${buttonSize}px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 100%;
  background: #ccc;
  border: solid 1px #888;
  user-select: none;
  transition: all ${CSSTransitionTimeout()};
  cursor: pointer;

  ${stateTransitions.notAppeared} {
    opacity: 0;
    margin-top: 300px;
  }

  ${stateTransitions.appear} {
    opacity: 1;
    margin-top: 0px;
  }

  ${stateTransitions.disappear} {
    opacity: 0;
    margin-top: 300px;
  }

  ${stateTransitions.appeared} {
    transition: transform 0.05s;
  }
`
