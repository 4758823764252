/* eslint-disable react/no-danger */
/* eslint-disable import/no-default-export */
import React from 'react'
import styled from 'styled-components'
import { Text as TextBase } from 'src/TempleDuCiel/components/Text'
import { useText } from 'src/TempleDuCiel/hooks/useText'
import {
  WBObjectModel,
  useWBObjectContext,
  WBObjectModelDefaultConfig,
} from 'src/WBApp/WBAppCore'

interface Data {
  text: string;
}

export type Text2Model = WBObjectModel<'Text', Data>

export const text2Config: WBObjectModelDefaultConfig<Data> = {
  transitionTimeout: 0,
}

const TextContainer = styled(TextBase)`
  position: absolute;
  padding-top: 250px;
  padding-left: 400px;
  padding-right: 230px;
`

export function Text2() {
  const object = useWBObjectContext<Text2Model>()
  const text = useText(object.data.text)

  return (
    <TextContainer>
      {text}
    </TextContainer>
  )
}
