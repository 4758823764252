import Bezier from 'bezier-js'
import React, { useRef, useCallback } from 'react'
import { BookStore } from 'src/TempleDuCiel/BookStore'
import { useWBAppContext, useWBObjectContext } from 'src/WBApp/WBAppCore'
import { ChoiceButtonModel } from './types'

export function DebugPoint({ x, y, color }: { color: string; x: number; y: number; }) {
  return (
    <div
      style={{
        position: 'absolute',
        left: x - 5,
        top: y - 5,
        width: 10,
        height: 10,
        backgroundColor: color,
        borderRadius: 10,
      }}
    />
  )
}

interface Point {
  x: number;
  y: number;
}

export function useChoicePoints(choiceCount: number): Point[] {
  const appContext = useWBAppContext()
  const { width, height } = appContext.appSize

  const firstPoint = [width * 0.1, height * 0.9]
  const firstPointAnchor = [width * 0.1, height * 0.2]
  const secondPoint = [width * 0.9, height * 0.9]
  const secondPointAnchor = [width * 0.9, height * 0.2]

  const curve = new Bezier(
    firstPoint[0],
    firstPoint[1],
    firstPointAnchor[0],
    firstPointAnchor[1],
    secondPointAnchor[0],
    secondPointAnchor[1],
    secondPoint[0],
    secondPoint[1],
  )

  const allPoints = curve.getLUT(choiceCount + 2)

  return allPoints.splice(1, choiceCount)
}

function distance(pointA: Point, pointB: Point) {
  return Math.sqrt(
    ((pointA.x - pointB.x) ** 2) + ((pointA.y - pointB.y) ** 2),
  )
}

export function useGetChoiceCompletedCoeff(initialChoiceBtnPos: Point, clientPoint: Point | null, points: Point[]) {
  const value = useRef<{
    completedCoeff: number;
    currentChoiceIndex: number | null;
  }>({
    completedCoeff: 0,
    currentChoiceIndex: null,
  })

  if (!clientPoint) {
    return value.current
  }

  const distanceAsObject: { [dist: number]: number; } = points
    .slice()
    .map((point) => distance(point, clientPoint))
    .reduce((obj, dist, index) => ({ ...obj, [dist]: index }), {})

  // @ts-ignore
  const minDistance = Math.min(...Object.keys(distanceAsObject))
  const currentChoiceIndex = distanceAsObject[minDistance]
  value.current.currentChoiceIndex = currentChoiceIndex
  const closestPoint = points[currentChoiceIndex]

  // distance between choice btn and touch location
  const x = distance(clientPoint, initialChoiceBtnPos)

  // distance between choice btn and choice item
  const y = distance(initialChoiceBtnPos, closestPoint)

  // distance between touch location and choice item
  const z = distance(clientPoint, closestPoint)

  const lineLength = y

  // check if it's really a triangle
  if ((x > y) || (z > y)) {
    // console.warn('Not a triangle')
    return value.current
  }

  // eslint-disable-next-line no-restricted-properties
  const acosPrev = (Math.pow(x, 2) + Math.pow(y, 2) - Math.pow(z, 2)) / (2 * x * y)

  // if cosPrev > 1, acos( acosPrev ) will be return NaN
  if (acosPrev > 1) {
    // console.warn('acosPrev is > 1')
    return value.current
  }

  const angleBetweenTouchLocationAndChoiceItem = Math.acos(acosPrev)

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(angleBetweenTouchLocationAndChoiceItem)) {
    // console.warn('WARNING: should be crash, angleBetweenTouchLocationAndChoiceItem is NaN')
    return value.current
  }

  const distanceFromChoiceBtn = Math.cos(angleBetweenTouchLocationAndChoiceItem) * x

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(distanceFromChoiceBtn)) {
    // console.warn('WARNING: should be crash, distanceFromChoiceBtn is NaN')
    return value.current
  }

  const completedCoeff = distanceFromChoiceBtn / lineLength

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(completedCoeff)) {
    // console.warn('WARNING: should be crash, percent is NaN')
    return value.current
  }

  return {
    currentChoiceIndex,
    completedCoeff,
  }
}

export function useGetChoiceButtonPos(
  completedCoeff: number,
  initialChoiceBtnPos: Point,
  currentChoicePoint: Point | null,
) {
  if (!currentChoicePoint) {
    return null
  }

  const buttonVectorPosX = currentChoicePoint.x - initialChoiceBtnPos.x
  const buttonVectorPosY = currentChoicePoint.y - initialChoiceBtnPos.y
  const buttonPosX = initialChoiceBtnPos.x + (buttonVectorPosX * completedCoeff)
  const buttonPosY = initialChoiceBtnPos.y + (buttonVectorPosY * completedCoeff)

  return {
    x: buttonPosX,
    y: buttonPosY,
  }
}

export function useOnChoiceCompleted() {
  const appContext = useWBAppContext<BookStore>()
  const objectContext = useWBObjectContext<ChoiceButtonModel>()

  const onChoiceCompleted = useCallback((choiceIndex: number) => {
    const choices = objectContext.data?.choices

    if (!choices) {
      throw new Error('no choice')
    }

    const { sceneId, store } = choices[choiceIndex]
    if (store) {
      appContext.setAppStore(store)
    }

    setTimeout(() => {
      appContext.pushScene(sceneId)
    }, 1000)
  }, [appContext, objectContext])

  return onChoiceCompleted
}
