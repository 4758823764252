import React from 'react'
import styled from 'styled-components'
import { HairType } from 'src/TempleDuCiel/BookStore'
import longCurlySrc from 'src/TempleDuCiel/assets/003-2bis/long-curly-hair.png'
import longStraightSrc from 'src/TempleDuCiel/assets/003-2bis/long-straight-hair.png'
import longWavySrc from 'src/TempleDuCiel/assets/003-2bis/long-wavy-hair.png'
import shortCurlySrc from 'src/TempleDuCiel/assets/003-2bis/short-curly-hair.png'
import shortStraightSrc from 'src/TempleDuCiel/assets/003-2bis/short-straight-hair.png'
import shortWavySrc from 'src/TempleDuCiel/assets/003-2bis/short-wavy-hair.png'

const hairsSrc: { [key in HairType]: string } = {
  shortCurly: shortCurlySrc,
  shortWavy: shortWavySrc,
  shortStraight: shortStraightSrc,
  longCurly: longCurlySrc,
  longWavy: longWavySrc,
  longStraight: longStraightSrc,
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const Image = styled.img`
  display: flex;
  width: 30%;
  cursor: pointer;
  user-select: none;
`

interface SelectHairTypeProps {
  onChange: (val: HairType) => void;
  value: HairType;
}

export function SelectHairType(props: SelectHairTypeProps) {
  const { onChange } = props

  return (
    <Container>
      {Object.entries(hairsSrc).map(([hairType, src]) => (
        <Image
          key={hairType}
          alt="Hair type"
          draggable={false}
          // @ts-ignore
          onClick={() => onChange(hairType)}
          src={src}
        />
      ))}
    </Container>
  )
}
