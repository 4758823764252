import React, { useMemo, useCallback } from 'react'
import { ThemeProvider } from 'styled-components'
import { useStateWithGetterObject } from '../../hooks/useStateWithGetterObject'
import { WBAppCoreProps, WBAppState, WBAppStore } from './WBApp.types'
import { WBScenes } from './WBScenes'
import { AppContext } from './context'
import { useGetSceneModel, usePushScene } from './utils'

export function WBAppCore(props: WBAppCoreProps) {
  const {
    story: appStory,
    objects: appObjects,
    size: appSize,
    offset: appOffset,
    window: appWindow,
    defaultStore,
    extraData = {},
    childrenBeforeScenes,
    childrenAfterScenes,
  } = props

  const getStartSceneId = () => {
    if (defaultStore && defaultStore.path) {
      const lastPathItem = defaultStore.path[defaultStore.path.length - 1]
      if (lastPathItem) {
        return lastPathItem
      }
    }

    return appStory.startSceneId
  }

  const [appState, setAppState, getAppState] = useStateWithGetterObject<WBAppState>({
    currentSceneId: getStartSceneId(),
    allowPushScene: true,
  })

  const [, setAppStore, getAppStore] = useStateWithGetterObject<WBAppStore>({
    path: [],
    ...defaultStore,
  })

  const getSceneModel = useGetSceneModel(appStory)
  const pushScene = usePushScene(getAppState, setAppState, setAppStore)

  const appContextValue = useMemo(() => {
    return {
      appStory,
      appSize,
      appOffset,
      appObjects,
      appWindow,
      extraData,
      getAppState,
      getAppStore,
      getSceneModel,
      pushScene,
      setAppState,
      setAppStore,
    }
  }, [
    appStory,
    appSize,
    appOffset,
    appObjects,
    appWindow,
    extraData,
    getAppState,
    getAppStore,
    getSceneModel,
    pushScene,
    setAppState,
    setAppStore,
  ])

  // @ts-ignore
  console.log('appStore', getAppStore().heroProps)
  // console.log('appState', getAppState())

  const themeValue = useCallback((theme: object) => {
    return {
      ...theme,
      appSize,
    }
  }, [appSize])

  return (
    <AppContext.Provider value={appContextValue}>
      <ThemeProvider theme={themeValue}>
        {childrenBeforeScenes}
        {appState.currentSceneId && (
          <WBScenes />
        )}
        {childrenAfterScenes}
      </ThemeProvider>
    </AppContext.Provider>
  )
}

WBAppCore.defaultProps = {
  transitions: [],
}
